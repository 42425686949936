import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { WidgetsPageLayout } from '../components/layouts'

class WidgetsPageTemplatePTP extends PureComponent {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageWidgetsPtp')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', [])
        sitemap = sitemap.map((link) => link && link.node)
        return <WidgetsPageLayout page={page} site={site} sitemap={sitemap} className="ptp-root" />
    }
}

export default WidgetsPageTemplatePTP

export const pageQuery = graphql`
    query WidgetsPagePTPBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterHandle
            }
        }
        contentfulPageWidgetsPtp(slug: { eq: $slug }) {
            __typename
            title
            slug
            group {
                title
                slug
                contentful_id
                userSection
            }
            userSection
            sections {
                name
                fullWidth
                widgets {
                    __typename
                    ... on ContentfulWidgetSocialFeedPtp {
                        contentful_id
                        title
                        slug
                        userSection
                        feed
                    }
                    ... on ContentfulPageGroupPtp {
                        contentful_id
                        title
                        slug
                        userSection
                        headerImage {
                            file {
                                url
                            }
                        }
                        image {
                            file {
                                url
                            }
                        }
                        imagePosition
                        groups {
                            __typename
                            title
                            slug
                        }
                        pagegenericptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                        pagegroupptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                        pagewidgetsptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                    }
                    ... on ContentfulWidgetSearchPanelPtp {
                        contentful_id
                        title
                        description {
                            description
                        }
                        bgImage {
                            title
                            file {
                                url
                            }
                        }
                        showCategories
                        featureList {
                            __typename
                            ... on ContentfulWidgetStoryBlockPtp {
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                textButton
                                externalHyperlink
                                internalHyperlink {
                                    __typename
                                    ... on ContentfulPageDirectoryOfServicesPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                            }
                            ... on ContentfulWidgetLinkBlockPtp {
                                __typename
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                featureLink: link {
                                    __typename
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulWidgetLinkPtp {
                                        text
                                        url
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                                icon
                            }
                        }
                    }
                    ... on ContentfulWidgetStoryBlockPtp {
                        contentful_id
                        title
                        summary {
                            summary
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        textButton
                        externalHyperlink
                        internalHyperlink {
                            __typename
                            ... on ContentfulPageDirectoryOfServicesPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGroupPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageWidgetsPtp {
                                title
                                slug
                                userSection
                            }
                        }
                    }
                    ... on ContentfulWidgetHeroPtp {
                        contentful_id
                        title
                        searchText
                        type
                        summary {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        backgroundVideoUrl
                        featureList {
                            __typename
                            ... on ContentfulWidgetStoryBlockPtp {
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                externalHyperlink
                                internalHyperlink {
                                    __typename
                                    ... on ContentfulPageDirectoryOfServicesPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                            }
                            ... on ContentfulWidgetLinkBlockPtp {
                                __typename
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                featureLink: link {
                                    __typename
                                    ... on ContentfulPageDirectoryOfServicesPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulWidgetLinkPtp {
                                        text
                                        url
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                                icon
                            }
                        }
                        link {
                            text
                            url
                        }
                        linkColour
                    }
                    ... on ContentfulWidgetLinksPtp {
                        __typename
                        title
                        description {
                            description
                        }
                        backgroundColor
                        textColor
                        imagePosition
                        image {
                            title
                            file {
                                url
                            }
                        }
                        links {
                            __typename
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulWidgetLinkPtp {
                                text
                                url
                            }
                        }
                    }
                    ... on ContentfulWidgetLinkBlockPtp {
                        __typename
                        title
                        slug
                        summary {
                            summary
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                        buttonText
                        buttonLink: link {
                            __typename
                            ... on ContentfulPageDirectoryOfServicesPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulWidgetLinkPtp {
                                text
                                url
                            }
                            ... on ContentfulPageWidgetsPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGroupPtp {
                                title
                                slug
                                userSection
                            }
                        }
                    }
                    ... on ContentfulWidgetRichTextPtp {
                        contentful_id
                        content {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                    }
                    ... on ContentfulWidgetRichTextPtp {
                        contentful_id
                        content {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                    }
                    ... on ContentfulWidgetImpactPtp {
                        id
                        title
                        introduction {
                            introduction
                        }
                        contentful_id
                        items {
                            colour
                            icon {
                                file {
                                    url
                                }
                                description
                                title
                            }
                            text
                            url
                        }
                        link {
                            text
                            url
                        }
                    }
                }
            }
            showSocialShare
            metaDescription {
                metaDescription
            }
        }
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
